.node circle {
  fill: #fff;
  stroke: #fecd1b;
  stroke-width: 2px;
}

.node circle.found {
  stroke: #0132e4;
}

.node text {
  font: 12px sans-serif;
  fill: #485155;
}

path.link {
  fill: none;
  stroke: #fecd1b;
  stroke-width: 1.5px;
  stroke-dasharray: 2, 2;
}

path.link.found {
  stroke: #0132e4;
}
