@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}

@font-face {
  font-family: "nimbussans";
  src: url("./fonts/NimbusSans.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dotsfont";
  src: url("./fonts/dotsfont.eot");
  src: url("./fonts/dotsfont.eot?#iefix") format("embedded-opentype"),
    url("./fonts/dotsfont.woff") format("woff"),
    url("./fonts/dotsfont.ttf") format("truetype"),
    url("./fonts/dotsfont.svg#dotsfontregular") format("svg");
}

.concealed {
  font-family: "dotsfont";
  font-size: 0.5rem;
  @supports (-webkit-text-security: disc) {
    -webkit-text-security: disc;
    font-family: inherit;
    font-size: inherit;
  }
}
